import { cn } from "@lib/cn"
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef, HTMLAttributes, } from "react"
import { ButtonIcon, getIcon } from "../button/helpers/get-icon"

type DefaultProps = {
  size?: "regular" | "small"
}

type LinkVariant = DefaultProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color"> & {
    as?: "link"
  }

type ButtonVariant = DefaultProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color"> & {
    as?: "button"
  }
type SpanVariant = DefaultProps &
  Omit<HTMLAttributes<HTMLSpanElement>, "color"> & {
    as?: "span"
  }

type LinkType = LinkVariant | ButtonVariant | SpanVariant

type LinkProps = LinkType & {
  leftIcon?: ButtonIcon
}

export const Link = forwardRef<any, LinkProps>((props, ref) => {
  const {
    as = "link",
    className,
    size = "regular",
    leftIcon,
    children,
    ...rest
  } = props
  const Component = as === "link" ? "a" : as === "span" ? "span" : "button"
  return (
    // @ts-ignore
    <Component
      className={cn(
        "inline-flex items-center font-semibold text-shade-500 transition-[color,transform] duration-150",
        "hover:text-shade-400 disabled:cursor-not-allowed disabled:text-shade-500/50 disabled:hover:text-inherit",
        "focus-visible:ring-3 focus-visible:rounded-[3px] focus-visible:outline-0 focus-visible:ring-shade-300/50 active:scale-[0.98] active:before:opacity-0",
        size === "regular" && "text-base",
        size === "small" && "text-base lg:text-sm",
        className,
      )}
      ref={ref}
      data-size={size}
      {...rest}
    >
      {leftIcon && <span className="mr-2 mt-0.5">{getIcon(leftIcon, 20)}</span>}
      {children}
    </Component>
  )
})

Link.displayName = "Link"
