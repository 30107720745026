import { cn } from "@lib/cn"
import React, { FC, HTMLAttributes } from "react"
import { filterProps } from "theme-system"

export type BoxProps = Omit<HTMLAttributes<HTMLElement>, "color"> & {
  as?: React.ElementType
}

export const Box: FC<BoxProps> = ({
  children,
  className,
  as = "div",
  ...rest
}) => {
  const Element = as
  return (
    <Element className={cn(className)} {...filterProps(rest)}>
      {children}
    </Element>
  )
}
