import { ToastProps } from "@components/toast"
import { GetState, SetState } from "zustand"
import { StoreState } from "./types"

export type Toast = Omit<
  ToastProps,
  | "handleRequestClose"
  | "handleClickButton"
  | "isFirstVisible"
  | "index"
  | "isWrapperExpanded"
> & {
  id: string
}

export type State = {
  byId: string[]
  items: {
    [key: string]: Toast
  }
  maxHeight: number
}

export const slice = (
  set: SetState<StoreState>,
  get: GetState<StoreState>,
): State => ({
  byId: [],
  items: {},
  maxHeight: 58, // 58px is the height of a single toast with one line of text
})
